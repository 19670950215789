body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: visible !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  padding: 0;
  margin: 0;
}
ul {
  list-style-type: none;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.menu .hamburger {
  z-index: 100;
  position: relative;
  padding: 0.5rem;
  background-color: transparent;
  border: none;
  margin: 2rem;
  cursor: pointer;
}
.hamburger span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: black;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    opacity 0.55s ease;
}
.menu.is-open .hamburger span {
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -2px);
  background: #232323;
}
.menu.is-open .hamburger span:nth-child(2) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
.menu.is-open .hamburger span:nth-child(3) {
  transform: rotate(-45deg) translate(0, -5px);
}
.menu-content {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 50px;
  padding-top: 50px;
  padding-top: 125px;
  background: white;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0%);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  width: fit-content;
  height: 100vh;

  margin: 0;
}
.menu.is-open .menu-content {
  transform: translate(0%, 0%);
}
.menu-item {
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: 700;
  width: max-content;
}
.menu-item a {
  color: inherit;
  text-decoration: none;
}
.menu-item a:hover {
  color: #647a63;
  text-decoration: underline;
}
