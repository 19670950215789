.tweet {
    text-align: left;
    padding: 1rem;
    background-color: white;
    a {
        text-decoration: none;
        color: inherit;
    }
    img {
        width: 100%;
        object-fit: contain;
    }
    .tweet-content {
        color: #526451;
        text-decoration: none;
        font-weight: 700;
        line-height: 1.1;
        * {
            font-size: 1.2rem;
            color: inherit !important;
        }
        &:hover
        {
           text-decoration: underline;
        }
    }
    .author-infos {

        display: flex;
        text-align: left;
        margin-top: 1rem;
        width: fit-content;
        &:hover
        {
            text-decoration: underline;
        }
        .author-infos-name,
        .profil-image {
            display: block;
        }
        .author-infos-name {
            width: fit-content;
            a {
                color: inherit;
                text-decoration: none;
            }
            .author-name,
            .twitter-handle {
                font-size: 0.8rem;
                white-space: nowrap;
            }
            .author-name {
                font-weight: bold;
                color: rgb(15, 20, 25);
            }
            .twitter-handle {
                font-weight: normal;
                color: rgb(83, 100, 113);
            }
        }
        .profil-image {
            margin-right: 1rem;
            width: 50px;
            border-radius: 100%;
            height: 50px;
            object-fit: cover;
        }
    }
    .featured-image,
    .tweet-content {
        max-width: 500px !important;
    }
}

.selections-list {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    min-width: 100vw;
    transition: all 500ms linear;
    &::after {
        content: "";
        position: absolute;
        height: 6px;
        background-color: black;
        top: 50%;
        left: 0;
        right: 0;
        margin-left: -3px;
    }
    & > .selection {
        position: relative;
        width: fit-content;
        min-width: 50px;
        height: fit-content;
        .main-tweet-image {
            max-width: 500px !important;
        }

        &::after {
            content: "";
            position: absolute;
            width: 15px;
            height: 15px;
            left: 0;
            background-color: white;
            border: 4px solid black;
            bottom: calc(50% - 15px);
            border-radius: 50%;
            z-index: 1;
        }
        .selection-time {
            position: absolute;
            top: calc(50% + 30px);
            right: 0px;
            margin: auto;
            padding-right: 2.5rem;
            width: max-content;
            transform: rotate(-90deg);
            cursor: pointer;
            z-index: 100;
            transition: opacity 0.5s linear 1s;
        }
        &.open {
            &::after {
                background-color: black;
            }
            .selection-time {
                opacity: 0;
                transition: opacity 0.1s linear;
            }
        }
        .bottom-section,
        .top-section {
            position: absolute;
            margin: 7.5px;
            border-left: 4px solid black;
            transition: all 1s;
            height: max-content;
            width: max-content;
            min-width: 2.5rem;
        }
        .top-section {
            bottom: 50%;
            height: max-content;
            .tweet:last-of-type {
                margin-bottom: 2rem;
            }
        }

        .tweet {
            position: relative;
            width: max-content;
            padding: 1.5rem 2rem;
            &:first-of-type {
                &::before {
                    content: "";
                    display: block;
                    width: 20px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-bottom: 5px solid black;
                }
            }
            &::after {
                content: "";
                display: block;
                width: 20px;
                position: absolute;
                left: 0;
                bottom: 0;
                border-bottom: 5px solid black;
            }

            &.link-tweet {
                font-size: 2rem;
                padding: 0;
                border-radius: 100%;
                border: 4px solid black;
                width: 2.5rem;
                height: 2.5rem;
                line-height: 0rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
                cursor: pointer;
                transform: translateX(-50%);
                margin-top: 2rem;

                a {
                    color: inherit;
                    text-decoration: none;
                }
                &::after,
                &::before {
                    display: none;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &:hover {
                    background-color: black;
                    color: white;
                }
            }
        }

        .bottom-section {
            top: 50%;
            padding-top: 5rem;
            // position: relative;
            .end-of-selection {
                padding-bottom: calc(1rem + 5px);
                &::after {
                    content: "";
                    display: block;
                    width: 20px;
                    margin: 1rem 0;
                    position: absolute;
                    left: 0;
                    border-bottom: 5px solid black;
                }
            }
        }
        &.close {
            .top-section,
            .bottom-section {
                max-height: 0;
                padding: 0;
                overflow: hidden;
            }
        }
    }
}
